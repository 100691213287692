<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      class="mt-10"
      v-if="!loading_table"
    ></v-skeleton-loader>
    <v-card v-if="loading_table" class="mt-10" style="margin-bottom: 100px">
      <v-card-title>
        <h4>المعاملات</h4>
        <v-spacer></v-spacer>
        <v-text-field
          label="بحث"
          append-icon="search"
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="text-center"
        :headers="transicitions_headers"
        :items="transicitions"
        :search="search"
        :footer-props="$store.state.footer_props"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td v-if="item.is_admin">
              <v-chip v-if="item.From" small class="white--text" color="blue">
                {{ item.From.name }}
              </v-chip>
              <v-chip
                v-else-if="item.To"
                small
                class="white--text"
                color="blue"
              >
                {{ item.To.name }}
              </v-chip>
            </td>
            <!--/ add chip if admin -->
            <td v-else>
              <span v-if="item.From">
                {{ item.From.name }}
              </span>
              <span v-else-if="item.To">
                {{ item.To.name }}
              </span>
            </td>
            <!--/ normal text if isn't admin -->
            <!--============= / name and checking if admin did this transaction or not =============-->
            <td v-if="item.From">
              {{ !item.is_admin ? item.From.phone : "---" }}
            </td>
            <td v-else-if="item.To">
              {{ !item.is_admin ? item.To.phone : "---" }}
            </td>
            <!--/ pohne -->

            <td v-if="item.From">
              <span class="success--text">
                مستلم <v-icon color="success" small>arrow_upward</v-icon>
              </span>
            </td>

            <td v-else>
              <span class="error--text">
                مرسل <v-icon color="error" small>arrow_downward</v-icon>
              </span>
            </td>
            <!--/ Type of trasaction (check if it send or received) -->
            <td>
              USDT
              {{ Intl.NumberFormat("en-US").format(item.Amount.toFixed(1)) }}
              <v-icon color="success" small v-if="item.From">add</v-icon>
              <v-icon color="error" small v-else-if="item.To">remove</v-icon>
            </td>
            <!--/ amount -->
            <td>{{ item.created_at }}</td>
            <!--/ date of transaction -->
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "balances",
  data() {
    return {
      transicitions: [],
      search: "",
      loading_table: false,
      transicitions_headers: [
        {
          value: "name",
          text: "الأسم",
          align: "center",
        },
        {
          value: "phone",
          text: "رقم الهاتف",
          align: "center",
        },
        {
          value: "type",
          text: "نوع المعاملة",
          align: "center",
        },
        {
          value: "Amount",
          text: "المبلغ",
          align: "center",
        },

        {
          value: "created_at",
          text: "تاريخ المعاملة",
          align: "center",
        },
      ],
    };
  },
  methods: {
    get_transactions() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "transactions",
            method: "get",
          },
        })
        .then((res) => {
          this.transicitions = res.data.data;
          this.loading_table = true;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.get_transactions();
  },
};
</script>
